import axios from 'axios';
import { GlobalData } from '../global_data';

// 创建一个用于刷新 token 的 axios 实例
const refreshAxios = axios.create();

// 定义一个函数用于检查 Access Token 是否过期
const isAccessTokenExpired = () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    return true;
  }
  try {
    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    const expirationTime = payload.exp * 1000; // 将秒转换为毫秒
    // 当 token 还剩 1 分钟有效期时就开始刷新
    return expirationTime < (Date.now() + 60000);
  } catch (error) {
    console.error('Token parsing error:', error);
    return true;
  }
};

// 检查 Refresh Token 是否过期
const isRefreshTokenExpired = () => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    return true;
  }
  try {
    const payload = JSON.parse(atob(refreshToken.split('.')[1]));
    const expirationTime = payload.exp * 1000;
    return expirationTime < Date.now();
  } catch (error) {
    console.error('Refresh token parsing error:', error);
    return true;
  }
};

// 为全局的 axios 添加请求拦截器
axios.interceptors.request.use(
  async (config) => {
    // 定义不需要token的白名单路径
    const whiteList = ['/login_verify/', '/send_verification_code/', '/navi/'];

    // 如果请求路径在白名单中，直接放行
    if (whiteList.some(path => config.url?.includes(path))) {
      return config;
    }

    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    //console.log('accessToken:', accessToken);
    //console.log('refreshToken:', refreshToken);

    // 如果没有 token 或 refresh token 过期，跳转到登录页
    if (!accessToken || !refreshToken || isRefreshTokenExpired()) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login/';
      return Promise.reject('No valid tokens');
    }

    // 如果 access token 即将过期，使用 refresh token 获取新的 token
    if (isAccessTokenExpired()) {
      try {
        console.log('Refreshing access token...', `${GlobalData.serverURL}/get_token/`);
        const refreshResponse = await refreshAxios.post(
          `${GlobalData.serverURL}/get_token/`,
          { refresh_token: refreshToken }
        );

        if (refreshResponse.status === 200) {
          localStorage.setItem('access_token', refreshResponse.data.access_token);
          localStorage.setItem('refresh_token', refreshResponse.data.refresh_token);
          config.headers.Authorization = `Bearer ${refreshResponse.data.access_token}`;
        }
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login/';
        return Promise.reject(refreshError);
      }
    }

    // 使用当前的 access token
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default axios;