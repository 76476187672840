import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { GlobalData } from '../global_data';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  Subtitles as SubtitlesIcon,
  SubtitlesOff as SubtitlesOffIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Comment as ChatIcon,
  LooksOne as LooksOneIcon,
  LooksTwo as LooksTwoIcon,
  Looks3 as Looks3Icon,
  AddCircleOutline as AddCircleOutlineIcon,
  CheckCircle as CheckCircleIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  MenuBook as MenuBookIcon,
} from '@mui/icons-material';
import { PiChatSlash } from "react-icons/pi";
import {
  SubtitleContainer,
  SubtitleBase,
  SubtitleText,
  DictionaryPopup,
  WordTitle,
  Phonetic,
  Translation,
  Definition,
  containerStyle,
  videoStyle,
  buttonStyle,
  timeStyle,
  generateDynamicStyles,
  POS,
  ExtraInfo,
  Stars,
  PopupContent,
} from '../styles/PlayVideo2.styles';
import RecommendedVideos from './RecommendedVideos';
import { styled } from '@mui/material/styles';
import MyAppBar from './MyAppBar';
import QuestionInteraction from './QuestionInteraction';
import { Dialog, Menu, MenuItem, ListItemIcon, Box, Tooltip, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import { Popover } from 'antd';
import StudyRecordManager from './StudyRecordManager';
import PointsAnimation from './PointsAnimation';

// 在文件顶部添加工具函数
const parseTimeStringToSeconds = (timeString) => {
  if (!timeString) return 0;
  try {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return Number(minutes * 60 + seconds);
  } catch (error) {
    console.error('Error parsing time string:', timeString);
    return 0;
  }
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// 添加新的样式组件
const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  backgroundColor: '#000',
});

const ContentContainer = styled('div')({
  display: 'flex',
  flex: 1,
  width: '100%',
  height: 'calc(100vh - 64px)',
  overflow: 'hidden',
  backgroundColor: '#fff',
});

const MainContent = styled('div')({
  flex: 1,
  minWidth: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  padding: '16px',
});

const VideoWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  aspectRatio: '16/9',
  backgroundColor: '#000',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SideContent = styled('div')(({ theme }) => ({
  width: '350px',
  minWidth: '300px',
  maxWidth: '420px',
  height: '100%',
  backgroundColor: '#fff',
  borderLeft: '1px solid #e0e0e0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const VideoTitle = styled('h1')({
  color: '#000',
  fontSize: '1.2rem',
  margin: '0 0 16px 0',
  padding: '0',
  fontWeight: '500',
});

const PlayVideo2 = () => {
  // 把所有 state 声明移到最前面
  const [channel, setChannel] = useState('watch'); // 默认为 'watch'
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startTimeFromUrl = searchParams.get('startTime');

  // Refs
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const progressRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const lastTouchX = useRef(null);
  const hasSetInitialTime = useRef(false);

  // States
  const { videoFilename } = useParams();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loadedProgress, setLoadedProgress] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [tempProgress, setTempProgress] = useState(null);
  const [hoverPosition, setHoverPosition] = useState(null);
  const [subtitleUrl, setSubtitleUrl] = useState('');
  const [subtitleWords, setSubtitleWords] = useState([]);
  const [wordInfo, setWordInfo] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [showSubtitles, setShowSubtitles] = useState(true);
  const [videoTitle, setVideoTitle] = useState('');
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);
  const previousFullScreenState = useRef(false);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [lastSegment, setLastSegment] = useState(null);
  const [lessonPlan, setLessonPlan] = useState(null);
  const [isAIQuestionEnabled, setIsAIQuestionEnabled] = useState(true);
  const [aiQuestionMode, setAiQuestionMode] = useState(1); // 0: 关闭, 1: Easy, 2: Medium, 3: Hard
  const [popupHeight, setPopupHeight] = useState(300);
  const [isWordAdded, setIsWordAdded] = useState(false);
  const [isWindowActive, setIsWindowActive] = useState(true);
  const [showPointsAnimation, setShowPointsAnimation] = useState(false);
  const [handleQuestionAnswered, setHandleQuestionAnswered] = useState(null);

  const videoUrl = `${GlobalData.serverURL}/getvideofile/${videoFilename}`;

  // 处理积分动画
  const handlePointsEarned = useCallback(() => {
    console.log('PlayVideo2: handlePointsEarned 被调用');
    if (!showPointsAnimation) {
      console.log('当前 showPointsAnimation 状态:', showPointsAnimation);
      setShowPointsAnimation(true);
      console.log('设置 showPointsAnimation 为 true');
    }
  }, [showPointsAnimation]);

  // 处理问答记录回调
  const handleQuestionAnsweredCallback = useCallback((data) => {
    console.log('QuestionInteraction 触发问答记录:', data);
    if (!data || !data.question) {
      console.warn('无效的问答数据');
      return;
    }

    if (handleQuestionAnswered) {
      handleQuestionAnswered(data);
    } else {
      console.warn('handleQuestionAnswered 未定义');
    }
  }, [handleQuestionAnswered]);

  // 设置问答处理函数
  const setHandleQuestionAnsweredCallback = useCallback((handler) => {
    if (typeof handler === 'function') {
      console.log('StudyRecordManager 设置问答处理函数');
      setHandleQuestionAnswered(() => handler);
    }
  }, []);

  // 在组件的顶部添加 useEffect 来监听 URL 变化
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const channelParam = params.get('channel');
    if (channelParam) {
      setChannel(channelParam);
    }
  }, [location]);

  // 事件处理函数
  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // 处理进度条点击
  const handleProgressClick = (e) => {
    e.stopPropagation();
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    videoRef.current.currentTime = pos * duration;
  };

  // 处理鼠标移动显示控制器
  const handleMouseMove = () => {
    setShowControls(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    if (isPlaying) {
      controlsTimeoutRef.current = setTimeout(() => {
        if (!isDragging) {
          setShowControls(false);
        }
      }, 3000);
    }
  };

  // 处理键盘快捷键
  const handleKeyPress = (e) => {
    // 如果问题对话框打开，不处理视频相关的快捷键
    if (isQuestionDialogOpen) {
      return;
    }

    // 只有在问题对话框关闭时才处理视频快捷键
    if (e.code === 'Space') {
      e.preventDefault(); // 阻止页面滚动
      handlePlayPause();
    } else if (e.code === 'ArrowLeft') {
      videoRef.current.currentTime -= 5;
    } else if (e.code === 'ArrowRight') {
      videoRef.current.currentTime += 5;
    } else if (e.code === 'KeyF') {
      handleFullscreen();
    }
  };

  // 处理进度条拖动
  const handleProgressMouseDown = (e) => {
    e.stopPropagation();
    setIsDragging(true);
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    setTempProgress(pos * duration);
    containerRef.current?.classList.add('dragging');
  };

  const handleProgressMove = (e) => {
    if (isDragging) {
      const progressBar = progressRef.current;
      const rect = progressBar.getBoundingClientRect();
      const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
      setTempProgress(pos * duration);
    }
  };

  const handleProgressMouseUp = () => {
    if (isDragging && tempProgress !== null) {
      videoRef.current.currentTime = tempProgress;
      setCurrentTime(tempProgress);
    }
    setIsDragging(false);
    setTempProgress(null);
    containerRef.current?.classList.remove('dragging');
  };

  // 处理移动端触摸
  const handleTouchStart = (e) => {
    lastTouchX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!lastTouchX.current) return;

    const deltaX = e.touches[0].clientX - lastTouchX.current;
    const seekTime = 30 * (deltaX / window.innerWidth); // 30秒为快进/快退时间
    videoRef.current.currentTime = Math.max(0, Math.min(duration, videoRef.current.currentTime + seekTime));

    lastTouchX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    lastTouchX.current = null;
  };

  // 添加日志频容器的高度变化
  const logContainerSize = () => {
    if (containerRef.current) {
      console.log('Container size:', {
        height: containerRef.current.offsetHeight,
        videoHeight: videoRef.current?.offsetHeight,
        clientHeight: containerRef.current.clientHeight,
      });
    }
  };

  // 分词函数
  const splitIntoWords = (text) => {
    // 1. 首先移除时间戳和<c>标签
    const cleanText = text.replace(/<\d{2}:\d{2}:\d{2}\.\d{3}><c>(.*?)<\/c>/g, "$1");

    // 2. 将文本分成斜体和非斜体部分
    const segments = cleanText.split(/(<i>.*?<\/i>)/);

    // 3. 处理每个部分
    const result = [];
    segments.forEach(segment => {
      if (segment.trim()) {
        // 处理特殊符号和音效标记
        const specialSymbols = ["♪", "\\[.*?\\]"];
        const specialSymbolsRegex = new RegExp(`(${specialSymbols.join("|")})`);

        // 分词模式
        const pattern = new RegExp(
          `(${specialSymbols.join("|")}|` +  // 特殊符号
          "[a-zA-Z]+(?:[-''][a-zA-Z]+)*|" +  // 单词
          "[^a-zA-Z\\s]+|" +                 // 标点
          "\\s+)",                           // 空格
          "g"
        );

        if (segment.startsWith('<i>')) {
          // 对斜体文本内容进行分词
          const italicContent = segment.replace(/<\/?i>/g, "");
          const matches = italicContent.match(pattern) || [];
          result.push(...matches.map(token => ({
            text: token,
            isWord: /^[a-zA-Z]+(?:[-''][a-zA-Z]+)*$/.test(token),
            isSpace: /^\s+$/.test(token),
            isSpecial: specialSymbolsRegex.test(token),
            isItalic: true,  // 标记为斜体
            sentence: text
          })));
        } else {
          // 处理非斜体文本
          const matches = segment.match(pattern) || [];
          result.push(...matches.map(token => ({
            text: token,
            isWord: /^[a-zA-Z]+(?:[-''][a-zA-Z]+)*$/.test(token),
            isSpace: /^\s+$/.test(token),
            isSpecial: specialSymbolsRegex.test(token),
            isItalic: false,
            sentence: text
          })));
        }
      }
    });

    return result;
  };

  // 处理单词点击
  const handleWordClick = async (word, event, sentence) => {
    try {
      event.stopPropagation();

      if (videoRef.current) {
        videoRef.current.pause();
        setIsPlaying(false);
      }

      const cleanWord = word
        .replace(/[.,!?;:"]/g, '')
        .toLowerCase();

      // 计算弹窗位置
      const rect = event.target.getBoundingClientRect();
      const popupWidth = 320;
      const defaultHeight = 300;
      const availableSpace = rect.top;
      const popupHeight = availableSpace < defaultHeight ?
        Math.max(100, availableSpace - 20) :
        defaultHeight;

      let x = rect.left;
      let y = rect.top - popupHeight - 10;

      if (x < 0) x = 0;
      if (x + popupWidth > window.innerWidth) {
        x = window.innerWidth - popupWidth;
      }

      // 获取当前字幕片段的时间范围
      let startTime = 0;
      let endTime = 0;
      const track = videoRef.current.textTracks[0];
      if (track && track.activeCues && track.activeCues.length > 0) {
        const cue = track.activeCues[0];
        startTime = Math.max(0, cue.startTime - 1); // 开始时间减1秒
        endTime = cue.endTime + 1; // 结束时间加1秒
      }

      // 新增：获取上下文句子
      const sentenceContext = await getSentenceContext(sentence); // 假设有一个函数获取上下文

      // 先显示加载中的弹窗
      setWordInfo({
        word: cleanWord,
        loading: true,
        dictionaryLoading: true,
        contextLoading: true,
        sentence: sentence,
        sentenceContext: sentenceContext, // 新增上下文信息
        startTime: startTime, // 保存开始时间
        endTime: endTime // 保存结束时间
      });
      setPopupPosition({ x, y });
      setPopupHeight(popupHeight);
      setShowPopup(true);
      setIsWordAdded(false);

      // 并行执行三个请求：字典查询、LLM解释和检查单词是否存在
      const [dictPromise, llmPromise, checkWordPromise] = [
        // 字典查询
        fetch(`${GlobalData.serverURL}/query_word/${cleanWord}`)
          .then(response => response.json())
          .catch(error => {
            console.error('字典查询失败:', error);
            return { error: '字典查询失败' };
          }),

        // LLM上下文解释查询
        fetch(`${GlobalData.serverURL}/llm_run/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `请根据以下信息解释单词"${cleanWord}"的含义和用法，用中文回答：
                  1. 这个单词是在一个视频中出现的，视频的主题概述：${lessonPlan?.summary || ''}
                  2. 这个单词所在句子：${sentence}
                  请从以下几个方面进行解释：
                  1. meaning: String类型，结合所在视频和句子，解释单词的含义，但输出内容不要强调"结合视频和句子、这个语境等",可以用"在这里"来表示
                  2. grammar: String类型，结合所在句子，解释单词的语法功能，但输出内容不要强调"结合句子、这个语境等",可以用"在这里"来表示
                  `,
            history: []
          })
        })
          .then(response => response.json())
          .catch(error => {
            console.error('上下文解释查询失败:', error);
            return { error: '上下文解释查询失败' };
          }),

        // 检查单词是否已在单词本中
        fetch(`${GlobalData.serverURL}/check_word/${cleanWord}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        })
          .then(response => response.json())
          .catch(error => {
            console.error('检查单词状态失败:', error);
            return { exists: false };
          })
      ];

      // 字典查询完成后立即更新
      dictPromise.then(data => {
        setWordInfo(prev => ({
          ...prev,
          ...(data.error ? { dictionaryError: data.error } : data),
          dictionaryLoading: false
        }));
      });

      // LLM解释完成后立即更新
      llmPromise.then(data => {
        setWordInfo(prev => ({
          ...prev,
          contextExplanation: {
            meaning: data.meaning || '',
            grammar: data.grammar || ''
          },
          contextLoading: false
        }));
      });

      // 检查单词是否存在
      checkWordPromise.then(data => {
        setIsWordAdded(data.exists);
      });

    } catch (error) {
      console.error('查询单词失败:', error);
      setWordInfo(prev => ({
        ...prev,
        loading: false,
        error: '查询失败，请稍后重试'
      }));
    }
  };

  // 新增：获取上下文句子的函数
  const getSentenceContext = (currentSentence) => {
    // 假设有一个方法可以获取当前句子的前后句
    // 这里需要根据实际情况实现获取上下文的逻辑
    const previousSentence = "前一句的内容"; // 需要替换为实际获取的内容
    const nextSentence = "后一句的内容"; // 需要替换为实际获取的内容
    return `${previousSentence} ${currentSentence} ${nextSentence}`;
  };

  // 将时间字符串转换为秒数
  const timeToSeconds = (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') return 0;

    try {
      let hours = 0, minutes = 0, seconds = 0;
      const parts = timeStr.split(':').map(part => parseInt(part, 10));

      switch (parts.length) {
        case 3: // HH:MM:SS
          [hours, minutes, seconds] = parts;
          break;
        case 2: // MM:SS
          [minutes, seconds] = parts;
          break;
        case 1: // SS
          [seconds] = parts;
          break;
        default:
          console.warn(`Unsupported time format: ${timeStr}`);
          return 0;
      }

      if (
        isNaN(hours) || isNaN(minutes) || isNaN(seconds) ||
        hours < 0 || minutes < 0 || seconds < 0 ||
        minutes >= 60 || seconds >= 60
      ) {
        console.warn(`Invalid time values: ${timeStr}`);
        return 0;
      }

      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      if (!isFinite(totalSeconds)) {
        console.warn(`Invalid time calculation result for: ${timeStr}`);
        return 0;
      }

      return totalSeconds;
    } catch (error) {
      console.error(`Error converting time: ${timeStr}`, error);
      return 0;
    }
  };

  // 在视频加载完成后设置初始时间
  useEffect(() => {
    const video = videoRef.current;
    if (!video || hasSetInitialTime.current) return;

    const handleMetadataLoaded = () => {
      if (startTimeFromUrl && !hasSetInitialTime.current) {
        const startTimeSeconds = timeToSeconds(startTimeFromUrl);
        if (startTimeSeconds > 0 && startTimeSeconds < video.duration) {
          video.currentTime = startTimeSeconds;
          hasSetInitialTime.current = true;
        }
      }
    };

    video.addEventListener('loadedmetadata', handleMetadataLoaded);

    return () => {
      video.removeEventListener('loadedmetadata', handleMetadataLoaded);
    };
  }, [startTimeFromUrl]);

  // 在视频加载 useEffect 中添加字幕加载
  useEffect(() => {
    const fetchSubtitle = async () => {


      // 加载字幕
      try {
        const subtitleUrl = `${GlobalData.serverURL}/get_subtitle/${videoFilename}`;
        const subtitleResponse = await fetch(subtitleUrl);

        if (subtitleResponse.ok) {
          const subtitleBlob = await subtitleResponse.blob();
          const subtitleObjectUrl = URL.createObjectURL(subtitleBlob);
          setSubtitleUrl(subtitleObjectUrl);
        }
      } catch (subtitleError) {
        console.error('字幕加载错误:', subtitleError);
      }

    };

    fetchSubtitle();

    return () => {
      if (subtitleUrl) {
        URL.revokeObjectURL(subtitleUrl);
      }
    };
  }, [videoFilename]);

  // 点击外部关闭弹窗
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.dictionary-popup')) {
        setShowPopup(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // 视频事件监
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // 只在 videoUrl 改变时视频
    if (!video.src) {
      video.load();
    }

    // 监听视频加载完成事件
    const handleMetadata = () => {
      setDuration(video.duration);
      console.log('Video loaded:', {
        height: video.offsetHeight,
        naturalHeight: video.videoHeight,
      });
      logContainerSize();
    };

    // 使用 addEventListener 而不是 onloadedmetadata
    video.addEventListener('loadedmetadata', handleMetadata);

    // 监听播放状态
    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);

    // 监播放度更新
    const handleTimeUpdate = () => {
      if (!video.paused) {
        setCurrentTime(video.currentTime);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    // 添加加载进度监听
    const handleProgress = () => {
      if (video.buffered.length > 0) {
        // 获取最后一个已加载片段的结束位置
        const bufferedEnd = video.buffered.end(video.buffered.length - 1);
        const duration = video.duration;
        setLoadedProgress((bufferedEnd / duration) * 100);
      }
    };

    video.addEventListener('progress', handleProgress);
    // 也监听 timeupdate 事件来更新加载进度
    video.addEventListener('timeupdate', handleProgress);

    // 添加字幕轨道事件监听
    const handleTrackLoad = () => {
      console.log('字幕轨道加载完成');
      const track = video.textTracks[0];
      if (track) {
        console.log('找到字幕轨道:', track);
        track.mode = 'hidden';

        track.addEventListener('cuechange', () => {
          console.log('字幕更新:', track.activeCues);
          if (track.activeCues && track.activeCues.length > 0) {
            const cueText = track.activeCues[0].text;
            console.log('当前幕本:', cueText);
            const words = splitIntoWords(cueText);
            setSubtitleWords(words);
          } else {
            setSubtitleWords([]);
          }
        });
      }
    };

    // 当视频加载完成后设置字幕轨道
    video.addEventListener('loadedmetadata', () => {
      if (video.textTracks.length > 0) {
        handleTrackLoad();
      }
    });

    // 监听字幕轨道加载
    video.textTracks.addEventListener('addtrack', handleTrackLoad);

    // 清理事件监听
    return () => {
      video.removeEventListener('loadedmetadata', handleMetadata);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('progress', handleProgress);
      video.removeEventListener('timeupdate', handleProgress);

      // 清理字幕轨道事件监听
      if (video.textTracks.length > 0) {
        const track = video.textTracks[0];
        if (track) {
          track.removeEventListener('cuechange', () => { });
        }
      }
      video.textTracks.removeEventListener('addtrack', handleTrackLoad);
    };
  }, [videoUrl]); // 只在 videoUrl 改变时重行

  // 动态样式
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = generateDynamicStyles(showControls);
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [showControls]);

  // 全屏和键盘事件监听
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenNow = (
        document.fullscreenElement === containerRef.current ||
        document.webkitFullscreenElement === containerRef.current ||
        document.mozFullScreenElement === containerRef.current ||
        document.msFullscreenElement === containerRef.current
      );
      setIsFullscreen(isFullscreenNow);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    // 添加事件监听
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('mousemove', handleProgressMove);
    document.addEventListener('mouseup', handleProgressMouseUp);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('mousemove', handleProgressMove);
      document.removeEventListener('mouseup', handleProgressMouseUp);
    };
  }, [handleKeyPress, handleProgressMove, handleProgressMouseUp]);

  // 加处理视频击的数
  const handleVideoClick = (e) => {
    // 如果击的是制或控制内的元素，不处理点击事件
    if (e.target.closest('.video-controls')) {
      return;
    }
    handlePlayPause();
  };

  // 在其他事件处理函数旁边添加 handleFullscreen 函数
  const handleFullscreen = async () => {
    try {
      if (!document.fullscreenElement) {
        if (containerRef.current.requestFullscreen) {
          await containerRef.current.requestFullscreen();
        } else if (containerRef.current.webkitRequestFullscreen) {
          await containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.mozRequestFullScreen) {
          await containerRef.current.mozRequestFullScreen();
        } else if (containerRef.current.msRequestFullscreen) {
          await containerRef.current.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          await document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.error('全屏切换失败:', err);
    }
  };

  // 添加处理频道换的函数
  const handleChannelChange = (index) => {
    const channels = ['watch', 'words', 'chat'];
    navigate(`/navi?channel=${channels[index]}`);
  };

  const navigate = useNavigate();

  // 在组件内添加获取视频信息的函数
  const fetchVideoInfo = async () => {
    try {
      const response = await fetch(`${GlobalData.serverURL}/get_video_info/${videoFilename}`);
      if (response.ok) {
        const data = await response.json();
        setVideoTitle(data.title || videoFilename); // 如果没有标题就使用文件名
      }
    } catch (error) {
      console.error('获取视频信息失败:', error);
      setVideoTitle(videoFilename); // 出错时使用文件名作为标题
    }
  };

  // 在 useEffect 中调用
  useEffect(() => {
    fetchVideoInfo();
  }, [videoFilename]);

  // 处理问答对话框的开关
  const handleQuestionDialog = (forceOpen = false) => {
    if (!forceOpen && !isAIQuestionEnabled) return;

    if (!isQuestionDialogOpen) {
      // 打开对话框时暂停视频
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      // 关闭对话框时继续播放视频
      videoRef.current?.play();
      setIsPlaying(true);
    }

    setIsQuestionDialogOpen(!isQuestionDialogOpen);
  };

  // 在 useEffect 中添加获取 LessonPlan 逻辑
  useEffect(() => {
    const fetchLessonPlan = async () => {
      try {
        const response = await axios.get(`${GlobalData.serverURL}/lesson_plan/?video_filename=${videoFilename}`);
        if (response.status === 200) {
          setLessonPlan(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch lesson plan:', error);
      }
    };

    fetchLessonPlan();
  }, [videoFilename]);

  // 添加检查片段播放的函数
  const checkSegmentPlayback = (playedSeconds) => {
    if (!lessonPlan || aiQuestionMode === 0) return;

    // 根据当前选择的难度过滤片段
    const segments = lessonPlan.segments.filter(segment =>
      segment.difficulty === (aiQuestionMode - 1)
    );

    // 确保时间比较是数值较
    const currentTimeSeconds = Number(playedSeconds);
    const newSegment = segments.find(segment => {
      const startTime = parseTimeStringToSeconds(segment.startTime);
      const endTime = parseTimeStringToSeconds(segment.endTime);
      return currentTimeSeconds >= startTime && currentTimeSeconds <= endTime;
    });

    // 用 JSON.stringify 来比较对象
    const currentSegmentStr = JSON.stringify(currentSegment);
    const newSegmentStr = JSON.stringify(newSegment);

    if (currentSegmentStr !== newSegmentStr) {
      console.log('Segment changed');
      console.log('From:', currentSegment);
      console.log('To:', newSegment);

      // 如果离开了一个片段（当前有片段，新状态没有片段）
      if (currentSegment && !newSegment) {
        console.log('Segment ended, opening dialog');
        setLastSegment(currentSegment);
        handleQuestionDialog(true);
        // 暂停视频播放
        if (videoRef.current) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }

      setCurrentSegment(newSegment);
    }
  };

  // 修改视频时间更新的处理函数
  const handleTimeUpdate = () => {
    if (!videoRef.current || !lessonPlan || aiQuestionMode === 0) return;

    const currentTimeSeconds = videoRef.current.currentTime;
    setCurrentTime(currentTimeSeconds);

    // 每秒只检查一次片段状态（可以根据需要调整检查频率）
    if (Math.floor(currentTimeSeconds * 2) !== Math.floor(currentTime * 2)) {
      checkSegmentPlayback(currentTimeSeconds);
    }
  };

  // 在视事件监听中添加 timeupdate 事件
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdateWrapper = () => {
      handleTimeUpdate();
    };

    video.addEventListener('timeupdate', handleTimeUpdateWrapper);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdateWrapper);
    };
  }, [lessonPlan, aiQuestionMode, currentTime]); // 添加 currentTime 作为依赖

  // 在 PlayPage 组件中添加 useEffect 来监 aiQuestionMode 的变化
  useEffect(() => {
    console.log('AI Question Mode changed:', aiQuestionMode);
    // 重置片段状态
    setCurrentSegment(null);
    setLastSegment(null);
  }, [aiQuestionMode]);

  // 添加重置片段的处理函数
  const handleSegmentReset = () => {
    setCurrentSegment(null);
    setLastSegment(null);
  };

  useEffect(() => {
    console.log('Popup state:', { showPopup, popupPosition, wordInfo });
  }, [showPopup, popupPosition, wordInfo]);

  // 添加处理问题对话框关闭的函数
  const handleQuestionDialogClose = () => {
    setIsQuestionDialogOpen(false);

    // 继续播放视频
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  // 在组件中添加处理添加单词的函数
  const handleAddWord = async (word) => {
    try {
      if (!wordInfo) {
        console.error('单词信息未加载完成');
        return;
      }

      const response = await fetch(`${GlobalData.serverURL}/add_word/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        },
        body: JSON.stringify({
          word: word,
          context: wordInfo.contextExplanation?.meaning || '',
          translation: wordInfo.translation || '',
          video_filename: videoFilename,
          subtitle_start_time: formatTime(wordInfo.startTime),
          subtitle_end_time: formatTime(wordInfo.endTime),
          subtitle_text: wordInfo.sentence || ''  // 使用保存的句子
        })
      });

      if (response.ok) {
        setIsWordAdded(true);
        // 可以添加成功提示
      } else {
        throw new Error('添加单词失败');
      }
    } catch (error) {
      console.error('添加单词失败:', error);
      // 可以添加错误提示
    }
  };

  // 添加窗口活动状态监听
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowActive(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <PageContainer>
      <Box sx={{ position: 'relative' }}>
        {showPointsAnimation && (
          <PointsAnimation
            points={10}
            onAnimationEnd={() => {
              console.log('动画结束，设置 showPointsAnimation 为 false');
              setShowPointsAnimation(false);
            }}
          />
        )}

        <StudyRecordManager
          videoFilename={videoFilename}
          isPlaying={isPlaying}
          isWindowActive={isWindowActive}
          isFullscreen={isFullscreen}
          onQuestionAnswered={setHandleQuestionAnsweredCallback}
          onPointsEarned={handlePointsEarned}
        />
      </Box>

      <MyAppBar
        title=""
        showSearch={channel === 'watch'}
        showAdd={channel === 'watch'}
        showTabs
        tabs={['看看片', '背背词', '聊聊天']}
        tabIndex={['watch', 'words', 'chat'].indexOf(channel)}
        setTabIndex={handleChannelChange}
      />
      <ContentContainer>
        <MainContent>
          <VideoTitle>{videoTitle}</VideoTitle>
          <VideoWrapper
            ref={containerRef}
            className="video-container"
            onClick={handleVideoClick}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => isPlaying && setShowControls(false)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <video
              ref={videoRef}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              playsInline
              preload="auto"
              autoPlay
              className="custom-video-player"
            >
              <source src={videoUrl} type="video/mp4" />
              {subtitleUrl && (
                <track
                  label="字幕"
                  kind="subtitles"
                  srcLang="en"
                  src={subtitleUrl}
                  default
                />
              )}
              您的浏览器不支持 HTML5 视频播放器
            </video>

            <SubtitleContainer
              style={{
                opacity: showSubtitles ? 1 : 0,
                pointerEvents: showSubtitles ? "auto" : "none",
                visibility: showSubtitles ? "visible" : "hidden"
              }}
            >
              {subtitleWords.map((token, index) => {
                if (token.isSpace) {
                  return <SubtitleBase key={index}>&nbsp;</SubtitleBase>;
                }

                return (
                  <SubtitleText
                    key={index}
                    onClick={(e) => token.isWord ? handleWordClick(token.text, e, token.sentence) : null}
                    clickable={token.isWord}
                    isItalic={token.isItalic}
                    isSpecial={token.isSpecial}
                  >
                    {token.text}
                  </SubtitleText>
                );
              })}
            </SubtitleContainer>

            {isQuestionDialogOpen && isFullscreen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  zIndex: 1000,
                }}
                onClick={(e) => {
                  // 防止点击背景时触发视频的点击事件
                  e.stopPropagation();
                }}
              >
                <Box
                  sx={{
                    width: '80%',
                    height: '80%',
                    maxWidth: '1200px',
                    maxHeight: '800px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                  }}
                  onClick={(e) => {
                    // 防止点击对话框时触发视频的点击事件
                    e.stopPropagation();
                  }}
                >
                  <QuestionInteraction
                    videoFilename={videoFilename}
                    segment={lastSegment || {}}
                    summary={lessonPlan?.summary || ''}
                    onClose={handleQuestionDialogClose}
                    onQuestionAnswered={handleQuestionAnsweredCallback}
                  />
                </Box>
              </Box>
            )}

            <ControlBar
              isPlaying={isPlaying}
              currentTime={currentTime}
              duration={duration}
              loadedProgress={loadedProgress}
              isDragging={isDragging}
              tempProgress={tempProgress}
              hoverPosition={hoverPosition}
              isFullscreen={isFullscreen}
              progressRef={progressRef}
              containerRef={containerRef}
              onPlayPause={handlePlayPause}
              onProgressClick={handleProgressClick}
              onProgressMouseDown={handleProgressMouseDown}
              onProgressMouseMove={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const pos = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
                setHoverPosition(pos * duration);
              }}
              onProgressMouseLeave={() => setHoverPosition(null)}
              onFullscreen={handleFullscreen}
              showSubtitles={showSubtitles}
              onSubtitlesToggle={() => setShowSubtitles(!showSubtitles)}
              onQuestionDialog={handleQuestionDialog}
              isAIQuestionEnabled={isAIQuestionEnabled}
              aiQuestionMode={aiQuestionMode}
              onAIQuestionModeChange={setAiQuestionMode}
              onSegmentReset={handleSegmentReset}
              lessonPlan={lessonPlan}
            />

            <DictionaryPopup
              className="dictionary-popup"
              show={showPopup}
              x={popupPosition.x}
              y={popupPosition.y}
              onClick={(e) => e.stopPropagation()}
            >
              <PopupContent onClick={(e) => e.stopPropagation()}>
                {wordInfo && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '8px'
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <WordTitle onClick={(e) => e.stopPropagation()}>{wordInfo.word}</WordTitle>
                        {wordInfo.phonetic && <Phonetic onClick={(e) => e.stopPropagation()}>[{wordInfo.phonetic}]</Phonetic>}
                      </div>
                      <span onClick={(e) => e.stopPropagation()}>
                        <Tooltip title={isWordAdded ? "已添加到单词本" : "添加到单词本"}>
                          <span onClick={(e) => e.stopPropagation()}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!isWordAdded) {
                                  handleAddWord(wordInfo.word);
                                }
                              }}
                              size="small"
                              sx={{
                                color: isWordAdded ? 'success.main' : 'primary.main',
                                '&:hover': {
                                  backgroundColor: isWordAdded ? 'transparent' : 'rgba(0, 0, 0, 0.04)'
                                },
                                '&.Mui-disabled': {
                                  color: 'success.main',
                                }
                              }}
                              disabled={isWordAdded}
                            >
                              {isWordAdded ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                      {/* 字典部分 */}
                      {wordInfo.dictionaryLoading ? (
                        <div style={{
                          textAlign: 'center',
                          marginTop: '15px',
                          fontSize: '14px'  // 缩小字体
                        }}>
                          <CircularProgress size={20} />
                          <div style={{ marginTop: '5px' }}>加载基本词义...</div>
                        </div>
                      ) : wordInfo.dictionaryError ? (
                        <div style={{ color: 'red', padding: '10px' }}>
                          {wordInfo.dictionaryError}
                        </div>
                      ) : (
                        <>
                          {/* {wordInfo.pos && <POS>词性: {wordInfo.pos}</POS>} */}
                          {wordInfo.translation && (
                            <div style={{ marginTop: '8px', whiteSpace: 'pre-line', fontSize: '14px' }}>
                              <strong>基本词义:</strong>
                              {wordInfo.translation.split('\n').map((trans, i) => (
                                <div key={i}>{trans}</div>
                              ))}
                            </div>
                          )}

                        </>
                      )}
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                      {/* 上下文解释部分 */}
                      {wordInfo.contextLoading ? (
                        <div style={{
                          textAlign: 'center',
                          marginTop: '15px',
                          fontSize: '14px'  // 缩小字体
                        }}>
                          <CircularProgress size={20} />
                          <div style={{ marginTop: '5px' }}>加载上下文解释...</div>
                        </div>
                      ) : wordInfo.contextError ? (
                        <div style={{
                          color: 'red',
                          marginTop: '15px',
                          fontSize: '14px'  // 缩小字体
                        }}>
                          {wordInfo.contextError}
                        </div>
                      ) : wordInfo.contextExplanation && (
                        <div style={{ marginTop: '15px' }}>
                          <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                            <div><strong>此处含义：</strong>{wordInfo.contextExplanation.meaning}</div>
                            <div style={{ marginTop: '8px' }}><strong>相关语法：</strong>{wordInfo.contextExplanation.grammar}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </PopupContent>
            </DictionaryPopup>
          </VideoWrapper>
        </MainContent>
        <SideContent>
          <RecommendedVideos currentVideoFilename={videoFilename} />
        </SideContent>
      </ContentContainer>

      {isQuestionDialogOpen && !isFullscreen && (
        <Dialog
          open={isQuestionDialogOpen}
          onClose={(event, reason) => {
            // 只有点击关闭按钮时才关闭对话框
            if (reason !== 'backdropClick') {
              handleQuestionDialogClose();
            }
          }}
          fullScreen={false}
          PaperProps={{
            sx: {
              height: '90%',           // 恢复固定高度
              width: '60%',           // 恢复固定宽度
              minWidth: 600,          // 最小宽度
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              zIndex: 1000
            }
          }}
        >
          <QuestionInteraction
            videoFilename={videoFilename}
            segment={lastSegment || {}}
            summary={lessonPlan?.summary || ''}
            onClose={handleQuestionDialogClose}
            onQuestionAnswered={handleQuestionAnsweredCallback}
          />
        </Dialog>
      )}
    </PageContainer>
  );
};

// 控制栏组件
const ControlBar = ({
  isPlaying,
  currentTime,
  duration,
  loadedProgress,
  isDragging,
  tempProgress,
  hoverPosition,
  isFullscreen,
  progressRef,
  containerRef,
  onPlayPause,
  onProgressClick,
  onProgressMouseDown,
  onProgressMouseMove,
  onProgressMouseLeave,
  onFullscreen,
  showSubtitles,
  onSubtitlesToggle,
  onQuestionDialog,
  isAIQuestionEnabled,
  aiQuestionMode,
  onAIQuestionModeChange,
  onSegmentReset,
  lessonPlan,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null); // 添加这行，用于保存按钮引用

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);  // 使用 currentTarget 而不是 target
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);  // 使用 currentTarget 而不是 target
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 100);
  };

  const handleModeSelect = (event, mode) => {
    event.preventDefault();
    event.stopPropagation();

    console.log('Selected mode:', mode);
    onAIQuestionModeChange(mode);
    onSegmentReset();
    handleClose();
  };

  // 获取当前难度对应的图标
  const getDifficultyIcon = () => {
    switch (aiQuestionMode) {
      case 0:
        return <PiChatSlash size={22} />;
      case 1:
        return <LooksOneIcon />;
      case 2:
        return <LooksTwoIcon />;
      case 3:
        return <Looks3Icon />;
      default:
        return <PiChatSlash size={22} />;
    }
  };

  // 获取当前难度对应的颜色
  const getDifficultyColor = () => {
    switch (aiQuestionMode) {
      case 0:
        return 'white'; // 聊天图标保持白色
      case 1:
        return '#4CAF50'; // 绿色代表 Easy
      case 2:
        return '#FF9800'; // 橙色代表 Medium
      case 3:
        return '#f44336'; // 红色代表 Hard
      default:
        return 'white';
    }
  };

  // 渲染问题时间点标记
  const renderQuestionMarkers = () => {
    if (!lessonPlan || aiQuestionMode === 0) return null;

    const segments = lessonPlan.segments.filter(segment =>
      segment.difficulty === (aiQuestionMode - 1)
    );

    return segments.map((segment, index) => {
      const endTime = parseTimeStringToSeconds(segment.endTime);
      const position = (endTime / duration) * 100;

      return (
        <div
          key={index}
          className="question-marker"
          style={{
            position: 'absolute',
            left: `${position}%`,
            top: '-2px',
            width: '3px',
            height: 'calc(100% + 4px)',
            backgroundColor: getDifficultyColor(),
            transform: 'translateX(-50%)',
            cursor: 'pointer',
            zIndex: 2,
          }}
          title={`问题时间点: ${formatTime(endTime)}`}
        />
      );
    });
  };

  return (
    <div className="video-controls">
      <button
        onClick={onPlayPause}
        style={buttonStyle}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </button>

      <span style={timeStyle}>
        {formatTime(currentTime)}
      </span>

      <div
        ref={progressRef}
        className="progress-bar"
        onClick={onProgressClick}
        onMouseDown={onProgressMouseDown}
        onMouseMove={onProgressMouseMove}
        onMouseLeave={onProgressMouseLeave}
        style={{
          cursor: 'pointer',
          position: 'relative', // 为对定的记提供参考
        }}
      >
        <div
          className="progress-loaded"
          style={{ width: `${loadedProgress}%` }}
        />
        <div
          className="progress-played"
          style={{ width: `${((isDragging && tempProgress !== null ? tempProgress : currentTime) / duration) * 100}%` }}
        />
        {renderQuestionMarkers()} {/* 添加问题标记 */}
        <div
          className="progress-handle"
          style={{
            left: `${((hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime)) / duration) * 100}%`
          }}
        />
        <div
          className="progress-time-tooltip"
          style={{
            left: `${((hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime)) / duration) * 100}%`
          }}
        >
          {formatTime(hoverPosition !== null ? hoverPosition : (isDragging && tempProgress !== null ? tempProgress : currentTime))}
        </div>
      </div>

      <span style={timeStyle}>
        {formatTime(duration)}
      </span>

      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          // 添加这些样式确保 Popover 全屏正确显示
          '& .ant-popover': {
            position: 'fixed',
            zIndex: 9999,
          }
        }}
        onMouseLeave={handleMouseLeave}
      >
        <Popover
          content={
            <Box
              sx={{
                width: 150,
                color: 'white',
                '& .MuiMenuItem-root': {
                  minHeight: '36px',
                  padding: '4px 12px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }
              }}
            >
              <MenuItem
                onClick={(e) => handleModeSelect(e, 0)}
                sx={{
                  borderRadius: '4px',
                  margin: '2px 0',
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <PiChatSlash size={20} style={{ color: 'white' }} />
                </ListItemIcon>
                <span style={{ fontSize: '14px' }}>关闭</span>
                {aiQuestionMode === 0 && <CheckIcon sx={{ ml: 1, fontSize: '18px' }} />}
              </MenuItem>
              <MenuItem
                onClick={(e) => handleModeSelect(e, 1)}
                sx={{
                  borderRadius: '4px',
                  margin: '2px 0',
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <LooksOneIcon sx={{ color: '#4CAF50', fontSize: '20px' }} />
                </ListItemIcon>
                <span style={{ fontSize: '14px' }}>Easy</span>
                {aiQuestionMode === 1 && <CheckIcon sx={{ ml: 1, fontSize: '18px', color: '#4CAF50' }} />}
              </MenuItem>
              <MenuItem
                onClick={(e) => handleModeSelect(e, 2)}
                sx={{
                  borderRadius: '4px',
                  margin: '2px 0',
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <LooksTwoIcon sx={{ color: '#FF9800', fontSize: '20px' }} />
                </ListItemIcon>
                <span style={{ fontSize: '14px' }}>Medium</span>
                {aiQuestionMode === 2 && <CheckIcon sx={{ ml: 1, fontSize: '18px', color: '#FF9800' }} />}
              </MenuItem>
              <MenuItem
                onClick={(e) => handleModeSelect(e, 3)}
                sx={{
                  borderRadius: '4px',
                  margin: '2px 0',
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <Looks3Icon sx={{ color: '#f44336', fontSize: '20px' }} />
                </ListItemIcon>
                <span style={{ fontSize: '14px' }}>Hard</span>
                {aiQuestionMode === 3 && <CheckIcon sx={{ ml: 1, fontSize: '18px', color: '#f44336' }} />}
              </MenuItem>
            </Box>
          }
          trigger="hover"
          placement="top"
          overlayStyle={{
            padding: 0,
            width: 168,
            // 添加这些样式确保在全屏模式下正确显示
            position: 'fixed',
            zIndex: 9999
          }}
          overlayInnerStyle={{
            padding: '8px',
            borderRadius: '8px',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
          }}
          title={<span style={{ padding: '16px', fontSize: '16px', color: 'white', fontWeight: 'bold' }}>AI口语问答</span>}
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.1}
          // 添加这些属性确保在全屏模式下正确工作
          getPopupContainer={() => containerRef.current || document.body}
          destroyTooltipOnHide={true}
        >
          <button
            ref={buttonRef}  // 添加引用
            id="ai-question-button"
            style={{
              ...buttonStyle,
              backgroundColor: 'transparent',
              color: getDifficultyColor(),
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={handleMouseEnter}
          >
            {getDifficultyIcon()}
          </button>
        </Popover>
      </Box>

      <button
        onClick={onSubtitlesToggle}
        style={buttonStyle}
        title={showSubtitles ? "隐藏字幕" : "显示字幕"}
      >
        {showSubtitles ? <SubtitlesIcon /> : <SubtitlesOffIcon />}
      </button>

      <button
        onClick={onFullscreen}
        style={buttonStyle}
        title={isFullscreen ? "退出全屏" : "全屏"}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </button>
    </div>
  );
};

export default PlayVideo2;