import styled from '@emotion/styled';

// 先定义通用滚动条样式
export const scrollbarStyle = {
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
};

// 字幕相关样式
export const SubtitleContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
  pointer-events: auto;
  user-select: none;
  width: 100%;
`;

export const SubtitleBase = styled.span`
  background: transparent;
  color: white;
  padding: 5px 2px;
  margin: 0;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  filter: drop-shadow(0px 0px 1px black) 
         drop-shadow(0px 0px 1px black)
         drop-shadow(0px 0px 1px black);
`;

export const SubtitleText = styled(SubtitleBase)`
  ${props => props.clickable && `
    cursor: pointer;
    &:hover {
      color: #ffbb34;
      text-decoration: underline;
    }
  `}
`;

// 字典弹窗相关样式
export const DictionaryPopup = styled.div`
  position: fixed;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  z-index: 1000;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: ${props => props.show ? 'block' : 'none'};
  width: 320px;
  height: 300px;
  overflow: hidden;
  border: 1px solid #eee;
`;

export const PopupContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 16px 0 0;
  margin-right: -16px;
  ${scrollbarStyle}
`;

export const WordTitle = styled.h3`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 18px;
`;

export const Phonetic = styled.p`
  color: #666;
  margin: 0 0 8px 0;
  font-style: italic;
`;

export const Translation = styled('div')({
  marginTop: '8px',
  whiteSpace: 'pre-line',
});

export const Definition = styled.div`
  margin: 8px 0;
  line-height: 1.4;
`;

export const POS = styled.div`
  margin-bottom: 8px;
  color: #666;
`;

export const ExtraInfo = styled.div`
  margin-top: 12px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9em;
  
  & > div {
    margin-bottom: 6px;
  }
  
  & strong {
    color: #444;
  }
`;

export const Stars = styled.span`
  color: #f5c518;
  margin-left: 4px;
`;

// 容器和视频样式
export const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
  overflow: 'hidden',
};

export const videoStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
};

export const buttonStyle = {
  background: 'none',
  border: 'none',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center'
};

export const timeStyle = {
  color: 'white',
  fontSize: '14px'
};

// 动态生成的CSS样式
export const generateDynamicStyles = (showControls) => `
  .video-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16 / 9;
  }

  .custom-video-player {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: opacity 0.3s ease;
    opacity: ${showControls ? '1' : '0'};
    pointer-events: ${showControls ? 'auto' : 'none'};
    z-index: 1;
    height: 40px;
    transform: translateZ(0);
  }

  .progress-bar {
    position: relative;
    height: 5px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    flex-grow: 1;
    transition: height 0.1s ease;
    border-radius: 5px;
  }

  .progress-bar:hover,
  .progress-bar:active {
    height: 8px;
  }

  .progress-loaded {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    transition: width 0.3s ease;
    border-radius: 5px;
  }

  .progress-played {
    position: absolute;
    height: 100%;
    background: #ffbb34;
    pointer-events: none;
    z-index: 1;
    border-radius: 5px;
  }

  .video-container.dragging .progress-bar {
    height: 8px;
  }

  .progress-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #ffbb34;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .progress-time-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    bottom: 20px;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .progress-bar:hover .progress-handle,
  .progress-bar:hover .progress-time-tooltip,
  .video-container.dragging .progress-handle,
  .video-container.dragging .progress-time-tooltip {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in;
  }

  .progress-handle,
  .progress-time-tooltip {
    visibility: hidden;
    transition: opacity 0s, visibility 0s;
  }
`; 