import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Avatar, Typography, Container, Paper } from '@mui/material';
import { GlobalData } from '../global_data';
import axios from 'axios';
import { message } from 'antd';

function ProfileEdit() {
  const [profile, setProfile] = useState({
    nickname: '',
    bio: '',
    avatar: null
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const unique_id = localStorage.getItem('unique_id');
        const response = await axios.get(`${GlobalData.serverURL}/get_user_profile/?unique_id=${unique_id}`);
        if (response.status === 200) {
          setProfile(response.data);
          if (response.data.avatar) {
            setPreviewUrl(`${GlobalData.serverURL}/avatars/${response.data.avatar}`);
          }
        }
      } catch (error) {
        console.error('获取用户资料失败:', error);
        message.error('获取用户资料失败');
      }
    };

    fetchProfile();
  }, []);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        message.error('图片大小不能超过5MB');
        return;
      }
      setProfile(prev => ({ ...prev, avatarFile: file }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('nickname', profile.nickname);
      formData.append('bio', profile.bio);
      if (profile.avatarFile) {
        formData.append('avatar', profile.avatarFile);
      }

      const unique_id = localStorage.getItem('unique_id');
      formData.append('unique_id', unique_id);

      const response = await axios.post(
        `${GlobalData.serverURL}/update_user_profile/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        message.success('资料更新成功');
      }
    } catch (error) {
      console.error('更新用户资料失败:', error);
      message.error('更新失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 3, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          编辑个人资料
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
            <Avatar
              src={previewUrl}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            <Button
              variant="outlined"
              component="label"
            >
              更换头像
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleAvatarChange}
              />
            </Button>
          </Box>

          <TextField
            fullWidth
            label="昵称"
            value={profile.nickname}
            onChange={(e) => setProfile(prev => ({ ...prev, nickname: e.target.value }))}
            margin="normal"
          />

          <TextField
            fullWidth
            label="个人简介"
            value={profile.bio}
            onChange={(e) => setProfile(prev => ({ ...prev, bio: e.target.value }))}
            margin="normal"
            multiline
            rows={4}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? '保存中...' : '保存修改'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default ProfileEdit; 