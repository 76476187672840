import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MyAppBar from './MyAppBar';
import VideoCard from './VideoCard';
import WordCard from './WordCard';
import { GlobalData } from '../global_data';
import axios from 'axios';
import { throttle } from 'lodash';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function NaviPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allBooks, setAllBooks] = useState([]); // 存储所有图书
  const [filteredBooks, setFilteredBooks] = useState([]); // 存储筛选后的图书
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]); // 改为数组以支持多选
  const [wordBookTab, setWordBookTab] = useState(0);
  const navigate = useNavigate();
  const unique_id = localStorage.getItem('unique_id');
  const [selectedBook, setSelectedBook] = useState(null);
  const [words, setWords] = useState([]);
  const [wordPage, setWordPage] = useState(1);
  const [hasMoreWords, setHasMoreWords] = useState(true);
  const [isLoadingWords, setIsLoadingWords] = useState(false);

  // 获取当前频道，默认为 'watch'
  const channel = searchParams.get('channel') || 'watch';

  // 处理频道切换
  const handleChannelChange = (index) => {
    const channels = ['watch', 'words', 'chat'];
    setSearchParams({ channel: channels[index] });
  };

  // 重置页面参数的函数
  const resetPageParams = () => {
    setVideos([]);
    setHasMore(true);
    setCurrentPage(1);
    setIsLoading(false);
  };

  // 检查页面是否需要加载更多的函数
  const checkNeedMore = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    return documentHeight <= windowHeight + 300;
  };

  // 获取图书列表和标签
  const fetchBooks = async () => {
    try {
      // 获取图书列表
      const booksResponse = await axios.get(`${GlobalData.serverURL}/get_books/`);

      // 获取单词本数量和用户资料
      const [countResponse, profileResponse] = await Promise.all([
        axios.get(
          `${GlobalData.serverURL}/get_wordbook_count/`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        ),
        axios.get(
          `${GlobalData.serverURL}/get_user_profile/?unique_id=${localStorage.getItem('unique_id')}`
        )
      ]);

      if (booksResponse.status === 200 && countResponse.status === 200) {
        const books = booksResponse.data.books;
        const wordCount = countResponse.data.count;
        const userProfile = profileResponse.data;

        // 创建我的单词本对象
        const myWordBook = {
          id: 'my-wordbook',
          image_name: userProfile.avatar
            ? `avatars/${userProfile.avatar}`  // 使用用户头像
            : 'icon/toptalk_logo_nobg.svg',  // 使用默认图标
          title: '我的单词本',
          word_count: wordCount,
          is_wordbook: true
        };

        // 将我的单词本放在列表首位
        const allBooksList = [myWordBook, ...books];
        setAllBooks(allBooksList);
        setFilteredBooks(allBooksList);

        // 从图书数据中提取所有标签
        const tagSet = new Set();
        books.forEach(book => {
          if (book.tags) {
            book.tags.split('、').forEach(tag => tagSet.add(tag));
          }
        });
        setTags(Array.from(tagSet).sort());
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Token已过期，需要重新登录');
      } else {
        console.error('获取数据失败:', error);
      }
    }
  };

  // 获取可用标签（基于当前选中的标签）
  const getAvailableTags = () => {
    if (selectedTags.length === 0) {
      return tags;
    }

    // 找出所有符合当前选中标签的图书（排除单词本）
    const matchingBooks = allBooks.filter(book => {
      if (book.is_wordbook) {
        return false; // 排除单词本
      }
      return selectedTags.every(tag => book.tags && book.tags.includes(tag));
    });

    // 从这些图书中提取所有可用的标签
    const availableTags = new Set();
    matchingBooks.forEach(book => {
      if (book.tags) {
        book.tags.split('、').forEach(tag => availableTags.add(tag));
      }
    });

    return Array.from(availableTags);
  };

  // 处理标签点击
  const handleTagClick = (tag) => {
    setSelectedTags(prevTags => {
      const isSelected = prevTags.includes(tag);
      let newTags;

      if (isSelected) {
        // 移除标签
        newTags = prevTags.filter(t => t !== tag);
      } else {
        // 添加标签
        newTags = [...prevTags, tag];
      }

      // 根据选中的标签筛选图书（使用AND逻辑）
      if (newTags.length === 0) {
        setFilteredBooks(allBooks); // 没有选中标签时显示所有图书（包括单词本）
      } else {
        const filtered = allBooks.filter(book => {
          if (book.is_wordbook) {
            return false; // 有选中标签时不显示单词本
          }
          // 使用every实现AND逻辑
          return newTags.every(tag => book.tags && book.tags.includes(tag));
        });
        setFilteredBooks(filtered);
      }

      return newTags;
    });
  };

  // 优化视频列表加载
  useEffect(() => {
    const fetchVideos = async () => {
      if (!hasMore || isLoading) {
        console.log('Skip loading more videos:', {
          hasMore,
          isLoading,
          currentPage,
          channel
        });
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${GlobalData.serverURL}/get_video_list/?page=${currentPage}&&exclude_unique_id=${unique_id}`
        );

        if (response.status === 200) {
          const newVideos = response.data.videos;
          setVideos(prev => [...prev, ...newVideos]);
          setHasMore(newVideos.length > 0);

          // 检查是否需要继续加载
          if (newVideos.length > 0 && checkNeedMore()) {
            console.log('Screen not filled, will load more...');
            setCurrentPage(prev => prev + 1);
          }
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (channel === 'watch') {
      fetchVideos();
    }
  }, [currentPage, channel, hasMore, unique_id]);

  // 监听频道切换
  useEffect(() => {
    resetPageParams();
    if (channel === 'words') {
      fetchBooks(); // 只需要调用一次fetchBooks
    }
  }, [channel]);

  // 优化滚动加载逻辑
  const handleScroll = throttle(() => {
    if (isLoading || !hasMore) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setCurrentPage(prev => prev + 1);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [handleScroll]);

  // 添加分割线样式常量
  const dividerTextStyle = {
    my: 2,
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      content: '""',
      borderTop: '1px solid',
      borderColor: 'divider',
      width: '80px',
      mx: 2
    }
  };

  // 处理图书点击事件
  const handleBookClick = (book) => {
    if (!book) return;

    const params = new URLSearchParams({
      bookId: (book.is_wordbook === true) ? '' : (book.book_id || ''),
      isWordbook: (!!book.is_wordbook).toString(),
      title: book.title || ''
    });
    window.open(`/wordlist?${params.toString()}`, '_blank');
  };

  // 获取单词列表
  const fetchWords = async (book, page) => {
    if (!book || isLoadingWords) return;

    console.log('开始获取单词列表:', {
      book_title: book.title,
      is_wordbook: book.is_wordbook,
      page: page
    });

    setIsLoadingWords(true);
    try {
      let response;
      if (book.is_wordbook) {
        // 获取用户的单词本
        console.log('获取用户单词本数据');
        response = await axios.get(
          `${GlobalData.serverURL}/get_word_book/?page=${page}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );
      } else {
        // 获取词书的单词
        console.log('获取普通词书数据');
        response = await axios.get(
          `${GlobalData.serverURL}/get_book_words/?book_id=${book.book_id}&page=${page}`
        );
      }

      if (response.status === 200) {
        const newWords = response.data.words;
        console.log('获取到的单词数据:', {
          count: newWords.length,
          sample: newWords.slice(0, 2).map(w => ({
            word: w.word,
            hasVideo: !!w.video_filename,
            videoInfo: w.video_filename ? {
              filename: w.video_filename,
              startTime: w.subtitle_start_time,
              endTime: w.subtitle_end_time
            } : null
          }))
        });

        setWords(prev => page === 1 ? newWords : [...prev, ...newWords]);
        setHasMoreWords(newWords.length > 0);
      }
    } catch (error) {
      console.error('获取单词列表失败:', error);
    } finally {
      setIsLoadingWords(false);
    }
  };

  // 处理单词列表滚动加载
  const handleWordsScroll = throttle(() => {
    if (isLoadingWords || !hasMoreWords) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setWordPage(prev => prev + 1);
    }
  }, 500);

  // 监听滚动事件
  useEffect(() => {
    if (selectedBook) {
      window.addEventListener('scroll', handleWordsScroll);
      return () => {
        window.removeEventListener('scroll', handleWordsScroll);
        handleWordsScroll.cancel();
      };
    }
  }, [selectedBook, isLoadingWords, hasMoreWords]);

  // 监听页码变化，加载更多单词
  useEffect(() => {
    if (selectedBook && wordPage > 1) {
      fetchWords(selectedBook, wordPage);
    }
  }, [wordPage]);

  // 渲染单词列表
  const renderWordList = () => {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => setSelectedBook(null)} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            {selectedBook.title}
          </Typography>
        </Box>

        {words.map((word, index) => (
          <WordCard
            key={`${word.word}-${index}`}
            word={word.word}
            definition={word.definition}
            translation={word.translation}
            phonetic={word.phonetic}
            videoFilename={word.video_filename}
            subtitleStartTime={word.subtitle_start_time}
          />
        ))}

        {isLoadingWords && (
          <Typography variant="body2" align="center" sx={dividerTextStyle}>
            加载中...
          </Typography>
        )}

        {!isLoadingWords && !hasMoreWords && words.length > 0 && (
          <Typography variant="body2" align="center" sx={dividerTextStyle}>
            到底啦
          </Typography>
        )}
      </Box>
    );
  };

  // 渲染图书列表
  const renderBooks = () => {
    const availableTags = getAvailableTags();

    return (
      <Box sx={{ width: '100%', p: 2 }}>
        {/* 标签筛选 */}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            mb: 2,
            flexWrap: 'wrap',
            gap: 1,
            '& > *': { mb: 1 }
          }}
        >
          {tags.map((tag) => {
            const isAvailable = availableTags.includes(tag);
            const isSelected = selectedTags.includes(tag);

            // 如果标签不可用且未被选中，则不显示
            if (!isAvailable && !isSelected) {
              return null;
            }

            return (
              <Chip
                key={tag}
                label={tag}
                onClick={() => handleTagClick(tag)}
                color={isSelected ? "primary" : "default"}
                variant={isSelected ? "filled" : "outlined"}
                sx={{
                  opacity: isAvailable ? 1 : 0.7,
                  transition: 'opacity 0.3s'
                }}
              />
            );
          })}
        </Stack>

        {/* 图书网格 */}
        <Grid2 container spacing={2}>
          {filteredBooks.map((book) => (
            <Grid2 item xs={12} sm={6} md={4} lg={3} key={book.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 6,
                  }
                }}
                onClick={() => handleBookClick(book)}
              >
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '75%', // 4:3 比例 (3/4 = 0.75)
                    width: '100%',
                    backgroundColor: '#f5f5f5' // 添加背景色
                  }}
                >
                  <CardMedia
                    component="img"
                    image={book.is_wordbook
                      ? `${GlobalData.serverURL}/${book.image_name}`  // 单词本的图片路径
                      : `${GlobalData.serverURL}/icon/${book.image_name}`  // 其他图书的图片路径
                    }
                    alt={book.title}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      p: 1  // 增加内边距，让图片不会太靠近边缘
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {book.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    单词数量: {book.word_count}
                  </Typography>
                  {!book.is_wordbook && book.tags && (
                    <Box sx={{ mt: 1 }}>
                      {book.tags.split('、').map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          size="small"
                          sx={{ mr: 0.5, mb: 0.5 }}
                        />
                      ))}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    );
  };

  // 渲染不同频道的内容
  const renderContent = () => {
    switch (channel) {
      case 'watch':
        return renderWatchChannel();
      case 'words':
        return selectedBook ? renderWordList() : renderBooks();
      case 'chat':
        return (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h5" color="text.secondary">
              聊聊天功能开发中...
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  // 修改视频列表渲染部分
  const renderWatchChannel = () => {
    return (
      <Grid2 container spacing={2} sx={{ padding: '16px' }}>
        {videos.map((video) => (
          <Grid2 item xs={12} sm={6} md={4} lg={3} xl={2} key={video.video_id}>
            <VideoCard
              title={video.title}
              stored_filename={video.stored_filename}
              isTranscode={video.is_transcode}
              originalFilename={video.original_filename}
              thumbnailUrl={`${GlobalData.serverURL}/thumbnails/${video.stored_filename.replace(
                '.mp4',
                ''
              )}.jpg`}
              pageType="others"
              nickname={video.nickname}
              avatar={video.avatar}
            />
          </Grid2>
        ))}

        {/* 加载中提示 */}
        {isLoading && (
          <Grid2 item xs={12}>
            <Typography variant="body2" align="center" sx={dividerTextStyle}>
              加载中...
            </Typography>
          </Grid2>
        )}

        {/* 到底啦提示 */}
        {!isLoading && !hasMore && videos.length > 0 && (
          <Grid2 item xs={12}>
            <Typography variant="body2" align="center" sx={dividerTextStyle}>
              到底啦
            </Typography>
          </Grid2>
        )}
      </Grid2>
    );
  };

  return (
    <div>
      <MyAppBar
        title=""
        showSearch={channel === 'watch'}
        showAdd={channel === 'watch'}
        showTabs
        tabs={['看看片', '背背词', '聊聊天']}
        tabIndex={['watch', 'words', 'chat'].indexOf(channel)}
        setTabIndex={handleChannelChange}
      />
      {renderContent()}
    </div>
  );
}

export default NaviPage; 