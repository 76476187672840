import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import PlayPage from './components/PlayPage';
import SearchPage from './components/SearchPage';
import UploadPage from './components/UploadPage';
import EditLessonPlanPage from './components/EditLessonPlanPage';
import BatchUpload from './components/BatchUpload';
import './components/axiosInstance';
import 'video-react/dist/video-react.css';
import ProfileEdit from './components/ProfileEdit';
import { MantineProvider } from '@mantine/core';
import StudyHistory from './components/StudyHistory';
import PointsDetailPage from './components/PointsDetailPage';
import NaviPage from './components/NaviPage';
import WordListPage from './components/WordListPage';

function App() {
  return (
    <BrowserRouter>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Routes>
          <Route path="/" element={<Navigate to="/navi" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/navi" element={<NaviPage />} />
          <Route path="/play/:videoFilename" element={<PlayPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/edit/:video_filename" element={<EditLessonPlanPage />} />
          <Route path="/batchupload" element={<BatchUpload />} />
          <Route path="/study-history" element={<StudyHistory />} />
          <Route path="/points-detail" element={<PointsDetailPage />} />
          <Route path="/profile-edit" element={<ProfileEdit />} />
          <Route path="/wordlist" element={<WordListPage />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;