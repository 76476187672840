import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { GlobalData } from '../global_data';
import VideoCard from './VideoCard';
import { throttle } from 'lodash';
import { scrollbarStyle } from '../styles/PlayVideo2.styles';

const RecommendedContainer = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  backgroundColor: '#fff',
  padding: '16px',
  ...scrollbarStyle
});

const VideoList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const RecommendedVideos = ({ currentVideoFilename }) => {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // 检查页面是否需要加载更多的函数
  const checkNeedMore = () => {
    const containerHeight = document.querySelector('.recommended-container')?.offsetHeight;
    const contentHeight = document.querySelector('.video-list')?.offsetHeight;
    return contentHeight <= containerHeight + 300;
  };

  // 获取视频列表
  useEffect(() => {
    const fetchVideos = async () => {
      if (!hasMore || isLoading) return;

      setIsLoading(true);
      try {
        const response = await fetch(
          `${GlobalData.serverURL}/get_video_list/?page=${currentPage}`
        );

        if (response.ok) {
          const data = await response.json();
          const filteredVideos = data.videos.filter(
            video => video.stored_filename !== currentVideoFilename
          );

          setVideos(prev => {
            const combined = [...prev, ...filteredVideos];
            const unique = combined.filter((v, i, a) =>
              a.findIndex(t => t.video_id === v.video_id) === i
            );
            return unique;
          });

          setHasMore(data.videos.length > 0);

          // 检查是否需要继续加载
          if (filteredVideos.length === 0 && data.videos.length > 0) {
            setCurrentPage(prev => prev + 1);
          }
        }
      } catch (error) {
        console.error('获取推荐视频失败:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, [currentPage, currentVideoFilename]);

  // 处理滚动加载
  const handleScroll = throttle((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading && hasMore) {
      setCurrentPage(prev => prev + 1);
    }
  }, 500);

  return (
    <RecommendedContainer
      className="recommended-container"
      onScroll={handleScroll}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontWeight: 500,
          padding: '0 8px 16px',
          borderBottom: '1px solid #e0e0e0'
        }}
      >
        推荐视频
      </Typography>
      <VideoList className="video-list">
        {videos.map((video) => (
          <VideoCard
            key={video.video_id}
            title={video.title}
            stored_filename={video.stored_filename}
            thumbnailUrl={`${GlobalData.serverURL}/thumbnails/${video.stored_filename.replace('.mp4', '')}.jpg`}
            isTranscode={video.is_transcode}
            originalFilename={video.original_filename}
            pageType="others"
            nickname={video.nickname}
            avatar={video.avatar}
          />
        ))}
        {isLoading && (
          <Typography variant="body2" align="center" sx={{ padding: '16px' }}>
            加载中...
          </Typography>
        )}
        {!isLoading && !hasMore && videos.length > 0 && (
          <Typography variant="body2" align="center" sx={{ padding: '16px' }}>
            没有更多视频了
          </Typography>
        )}
      </VideoList>
    </RecommendedContainer>
  );
};

export default RecommendedVideos; 