import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { GlobalData } from '../global_data';

// 添加 splitIntoWords 函数定义
const splitIntoWords = (text) => {
  if (!text) return [];
  // 简单返回文本作为单个项
  return [text];
};

// VideoPlayer 组件
const VideoPlayer = ({ videoFilename, startTime, endTime, word }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const videoContainerRef = useRef(null);
  const [subtitleWords, setSubtitleWords] = useState([]);
  const [subtitleUrl, setSubtitleUrl] = useState(''); // 添加字幕URL状态
  const endTimeSecondsRef = useRef(0); // 添加对结束时间的缓存
  const startTimeSecondsRef = useRef(0); // 添加对开始时间的缓存

  // 在组件挂载或时间变化时更新缓存的时间
  useEffect(() => {
    if (startTime) {
      startTimeSecondsRef.current = timeToSeconds(startTime);
      console.log('更新开始时间缓存:', {
        startTime,
        startTimeSeconds: startTimeSecondsRef.current
      });
    }
    if (endTime) {
      endTimeSecondsRef.current = timeToSeconds(endTime);
      console.log('更新结束时间缓存:', {
        endTime,
        endTimeSeconds: endTimeSecondsRef.current
      });
    }
  }, [startTime, endTime]);

  // 将时间字符串转换为秒数
  const timeToSeconds = (timeStr) => {
    console.log('开始时间转换:', {
      input: timeStr,
      type: typeof timeStr
    });

    // 如果是数字类型，直接返回
    if (typeof timeStr === 'number') {
      console.log('数字类型，直接返回:', timeStr);
      return timeStr;
    }

    // 如果是字符串类型，则按照时间格式解析
    if (typeof timeStr === 'string') {
      try {
        // 处理 MM:SS 或 HH:MM:SS 格式
        const parts = timeStr.split(':');
        console.log('时间转换详情:', {
          original: timeStr,
          parts: parts,
          partsLength: parts.length
        });

        let result = 0;
        switch (parts.length) {
          case 3: // HH:MM:SS
            result = (parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60) + parseInt(parts[2]);
            console.log('HH:MM:SS 格式，结果:', result);
            return result;
          case 2: // MM:SS
            result = (parseInt(parts[0]) * 60) + parseInt(parts[1]);
            console.log('MM:SS 格式，结果:', result);
            return result;
          case 1: // SS
            result = parseInt(parts[0]);
            console.log('SS 格式，结果:', result);
            return result;
          default:
            console.warn(`不支持的时间格式: ${timeStr}`);
            return 0;
        }
      } catch (error) {
        console.error(`时间转换错误:`, {
          input: timeStr,
          error: error.message
        });
        return 0;
      }
    }

    console.warn(`无效的时间值: ${timeStr}`);
    return 0;
  };

  // 处理播放/暂停
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // 处理重新播放
  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTimeSecondsRef.current;
      videoRef.current.play();
      setIsPlaying(true);
      setIsEnded(false);
    }
  };

  // 处理在 PlayPage 中播放
  const handlePlayInFull = () => {
    // 将秒数转换为 MM:SS 格式
    const formatTimeToMMSS = (seconds) => {
      if (typeof seconds !== 'number') {
        seconds = Number(seconds);
      }
      if (isNaN(seconds)) return "00:00";

      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // 使用缓存的开始时间
    const formattedStartTime = formatTimeToMMSS(startTimeSecondsRef.current);
    window.open(`/play/${videoFilename}?startTime=${formattedStartTime}`, '_blank');
  };

  // 监听视频时间更新
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (!video || !endTime || isEnded) return;

    if (endTimeSecondsRef.current > 0 && video.currentTime >= endTimeSecondsRef.current) {
      console.log('视频到达结束时间:', {
        currentTime: video.currentTime,
        endTimeSeconds: endTimeSecondsRef.current,
        endTime
      });
      video.pause();
      setIsPlaying(false);
      setIsEnded(true);
    }
  };

  // 监听视频加载完成
  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (!video || !startTime) return;

    console.log('视频加载完成，使用缓存的时间:', {
      startTimeSeconds: startTimeSecondsRef.current,
      endTimeSeconds: endTimeSecondsRef.current,
      videoCurrentTime: video.currentTime,
      videoDuration: video.duration
    });

    if (startTimeSecondsRef.current > 0 && startTimeSecondsRef.current < video.duration) {
      video.currentTime = startTimeSecondsRef.current;
      console.log('设置视频时间成功:', startTimeSecondsRef.current);
    } else {
      console.warn('无效的开始时间:', {
        startTimeSeconds: startTimeSecondsRef.current,
        videoDuration: video.duration
      });
    }
  };

  // 监听视频播放状态变化
  const handlePlayStateChange = () => {
    const isVideoPlaying = !videoRef.current.paused;
    setIsPlaying(isVideoPlaying);
    if (isVideoPlaying) {
      setIsEnded(false);
    }
  };

  // 修改 VideoPlayer 组件中的字幕处理
  const handleTrackLoad = () => {
    const video = videoRef.current;
    if (!video) return;

    // 确保所有轨道默认为隐藏模式
    for (let i = 0; i < video.textTracks.length; i++) {
      video.textTracks[i].mode = 'hidden';
    }

    // 等待一小段时间确保轨道加载完成
    setTimeout(() => {
      const track = video.textTracks[0];
      if (track) {
        console.log('字幕轨道加载:', {
          word: word,
          hasTrack: true,
          initialMode: track.mode,
        });

        track.mode = 'hidden';

        track.addEventListener('cuechange', () => {
          if (track.activeCues && track.activeCues.length > 0) {
            const cueText = track.activeCues[0].text;
            console.log('字幕更新:', {
              word: word,
              trackMode: track.mode,
              cueText: cueText,
              hasActiveCues: track.activeCues.length > 0
            });

            const words = splitIntoWords(cueText);
            setSubtitleWords(words);
          } else {
            setSubtitleWords([]);
          }
        });
      } else {
        console.log('字幕轨道加载失败:', {
          word: word,
          hasTrack: false,
          textTracksLength: video?.textTracks?.length
        });
      }
    }, 100);  // 100ms 延迟确保轨道加载
  };

  // 修改视频加载事件监听
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleVideoEvents = () => {
      const timeUpdateHandler = () => {
        if (!video || !endTime || isEnded) return;

        // 使用缓存的结束时间
        if (endTimeSecondsRef.current > 0 && video.currentTime >= endTimeSecondsRef.current) {
          video.pause();
          setIsPlaying(false);
          setIsEnded(true);
        }
      };

      video.addEventListener('timeupdate', timeUpdateHandler);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('play', handlePlayStateChange);
      video.addEventListener('pause', handlePlayStateChange);
      video.addEventListener('ended', () => setIsEnded(true));

      return () => {
        video.removeEventListener('timeupdate', timeUpdateHandler);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('play', handlePlayStateChange);
        video.removeEventListener('pause', handlePlayStateChange);
        video.removeEventListener('ended', () => setIsEnded(true));
      };
    };

    // 添加字幕轨道变化监听
    const handleTrackChange = () => {
      console.log('字幕轨道变化:', {
        word: word,
        tracksLength: video.textTracks.length
      });
      handleTrackLoad();
    };

    // 监听轨道加载
    video.textTracks.addEventListener('addtrack', handleTrackChange);

    // 初始化视频事件
    const cleanup = handleVideoEvents();
    // 初始尝试加载字幕轨道
    handleTrackLoad();

    return () => {
      cleanup();
      video.textTracks.removeEventListener('addtrack', handleTrackChange);
    };
  }, [startTime, endTime, word, isEnded]);

  // 修改字幕加载 useEffect
  useEffect(() => {
    const fetchSubtitle = async () => {
      try {
        const subtitleUrl = `${GlobalData.serverURL}/get_subtitle/${videoFilename}`;
        console.log('开始加载字幕:', {
          word: word,
          videoFilename: videoFilename
        });

        const subtitleResponse = await fetch(subtitleUrl);
        if (subtitleResponse.ok) {
          const subtitleBlob = await subtitleResponse.blob();
          const subtitleObjectUrl = URL.createObjectURL(subtitleBlob);
          setSubtitleUrl(subtitleObjectUrl);
          console.log('字幕加载成功:', {
            word: word,
            subtitleUrl: subtitleObjectUrl
          });

          // 字幕URL设置后，确保轨道处理程序被调用
          if (videoRef.current) {
            handleTrackLoad();
          }
        }
      } catch (subtitleError) {
        console.error('字幕加载错误:', {
          word: word,
          error: subtitleError
        });
      }
    };

    if (videoFilename) {
      fetchSubtitle();
    }

    return () => {
      if (subtitleUrl) {
        URL.revokeObjectURL(subtitleUrl);
      }
    };
  }, [videoFilename, word]);

  return (
    <Box
      ref={videoContainerRef}
      sx={{
        minWidth: '200px',
        maxWidth: '300px',
        position: 'relative',
        '&:hover .play-button': {
          opacity: 1,
        }
      }}
    >
      <video
        ref={videoRef}
        width="100%"
        style={{
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        controlsList="nodownload"
        preload="metadata"
        onClick={handlePlayPause}
      >
        <source
          src={`${GlobalData.serverURL}/getvideofile/${videoFilename}`}
          type="video/mp4"
        />
        {subtitleUrl && (
          <track
            label="字幕"
            kind="subtitles"
            srcLang="en"
            src={subtitleUrl}
            default
          />
        )}
        您的浏览器不支持视频标签。
      </video>

      {/* 字幕显示 */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '12px',
          background: 'linear-gradient(180deg, transparent 0%, rgba(0,0,0,0.4) 20%, rgba(0,0,0,0.75) 100%)',
          pointerEvents: 'none',
          zIndex: 2,
          minHeight: '60px',
          boxSizing: 'border-box',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(1px)',
            zIndex: -1,
          },
        }}
      >
        {subtitleWords.map((text, index) => (
          <Typography
            key={index}
            sx={{
              color: '#ffffff',
              textAlign: 'center',
              fontSize: 'clamp(12px, 2.5vw, 14px)',
              fontWeight: 600,
              textShadow: `
                -1px -1px 0 #000,
                1px -1px 0 #000,
                -1px 1px 0 #000,
                1px 1px 0 #000,
                0 2px 8px rgba(0,0,0,0.8)
              `,
              width: '100%',
              maxWidth: '280px',
              wordWrap: 'break-word',
              lineHeight: 1.4,
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              display: '-webkit-box',
              margin: '0 auto',
              padding: '2px 8px',
              letterSpacing: '0.3px',
              position: 'relative',
              zIndex: 1,
              textRendering: 'optimizeLegibility',
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
            }}
          >
            {text}
          </Typography>
        ))}
      </Box>

      {/* 播放按钮或结束后的按钮组 */}
      {isEnded ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            gap: '10px',
            zIndex: 3,
          }}
        >
          {/* 重播按钮 */}
          <Box
            onClick={handleReplay}
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
              <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
            </svg>
          </Box>
          {/* 在 PlayPage中播放按钮 */}
          <Box
            onClick={handlePlayInFull}
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
              <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
            </svg>
          </Box>
        </Box>
      ) : (
        <Box
          className="play-button"
          onClick={handlePlayPause}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '48px',
            height: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            opacity: isPlaying ? 0 : 1,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
          >
            {isPlaying ? (
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
            ) : (
              <path d="M8 5v14l11-7z" />
            )}
          </svg>
        </Box>
      )}
    </Box>
  );
};

// WordCard 组件
function WordCard({
  word,
  definition,
  translation,
  phonetic,
  videoFilename,
  subtitleStartTime,
  subtitleEndTime,
  subtitleText,
  context,
  videoTitle,
  isWordbook
}) {
  // 添加日志检查context
  console.log('WordCard接收到的参数:', {
    word,
    hasContext: !!context,
    contextValue: context,
    hasVideo: !!videoFilename,
    isWordbook
  });

  // 添加高亮显示单词的函数
  const highlightWord = (text, targetWord) => {
    if (!text || !targetWord) return text;

    // 创建一个不区分大小写的正则表达式
    const regex = new RegExp(`(${targetWord})`, 'gi');
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <span key={index} style={{
              backgroundColor: '#ffd700',
              padding: '0 2px',
              borderRadius: '2px',
              fontWeight: 'bold'
            }}>
              {part}
            </span>
          ) : part
        )}
      </>
    );
  };

  return (
    <Card
      sx={{
        width: '100%',
        mb: 2,
        '&:hover': {
          boxShadow: 6,
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h5" component="div">
                {word}
              </Typography>
              {phonetic && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ ml: 2 }}
                >
                  [{phonetic}]
                </Typography>
              )}
            </Box>
            {translation && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  pl: 2,
                  whiteSpace: 'pre-line',
                  mb: 2
                }}
              >
                {translation}
              </Typography>
            )}
            {subtitleText && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  pl: 2,
                  whiteSpace: 'pre-line',
                  mb: 1,
                  fontStyle: 'italic'
                }}
              >
                <strong>例句：</strong>{highlightWord(subtitleText, word)}
              </Typography>
            )}
            {context && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  pl: 2,
                  whiteSpace: 'pre-line',
                  fontStyle: 'italic'
                }}
              >
                <strong>解释：</strong>{context}
              </Typography>
            )}
            {isWordbook && videoTitle && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  pl: 2,
                  mt: 1,
                  fontSize: '0.85rem',
                  color: 'primary.main'
                }}
              >
                <strong>来自视频：</strong>{videoTitle}
              </Typography>
            )}
          </Box>
          {videoFilename && (
            <Box sx={{ ml: 2 }}>
              <VideoPlayer
                videoFilename={videoFilename}
                startTime={subtitleStartTime}
                endTime={subtitleEndTime}
                word={word}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default WordCard; 