import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, TextField } from '@mui/material';
import { Popover, Divider } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../global_data';
import axios from 'axios';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import StarIcon from '@mui/icons-material/Star';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import { message } from 'antd';

// 添加样式组件
const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(2),
    minWidth: '200px',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
  }
}));

const MenuItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

function MyAppBar({ title, showSearch, showAdd, showTabs, tabIndex, setTabIndex, tabs }) {
  const navigate = useNavigate();

  //console.log("页面刷新");


  const handleTabClick = (index) => {
    setTabIndex(index);
  };
  //console.log("tabindex", tabIndex);

  const generateTabContent = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
        {tabs.map((tab, index) => (
          <Typography
            key={index}
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: '#ffbb34',
              cursor: 'pointer',
              marginRight: index === tabs.length - 1 ? '0px' : '16px', // 最后一个 tab 不需要 marginRight
              fontWeight: tabIndex === index ? 'bold' : 'normal', // 设置字体粗细
              borderBottom: tabIndex === index ? '2px solid #ffbb34' : 'none', // 添加下划线
            }}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </Typography>
        ))}
      </Box>
    );
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleSearch = () => {
    window.open('/search', '_blank');
  };

  const handleAddVideo = () => {
    window.open('/upload', '_blank'); // 在新窗口打开 /upload 页面
  };

  return (
    <Box>
      <AppBar
        position="static" sx={{
          backgroundColor: 'white',
          boxShadow: 'none', // 移除阴影 或 '0px 2px 4px -1px rgba(0,0,0,0.2)', // 设置更柔和的阴影
        }}>
        <Toolbar>

          {/* 点击图标跳转到HomePage */}
          <img
            src={`${GlobalData.serverURL}/icon/toptalk_logo_nobg.svg`}
            alt="TopTalk Logo"
            width="40"
            height="40"
            style={{ cursor: 'pointer', marginRight: '16px' }} // 添加点击样式和右边距
            onClick={handleHome} // 添加点击事件处理函数
          />
          {showTabs && generateTabContent()} {/* 如果需要渲染 tabs，则渲染 */}

          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ffbb34' }}>
            {title}
          </Typography>

          {/* "搜索"图标 */}
          {showSearch && (
            <IconButton
              color="inherit"
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon style={{ color: '#ffbb34' }} />
            </IconButton>
          )}
          {/* "上传"图标 */}
          {showAdd && (
            <IconButton
              color="inherit"
              aria-label="add"
              onClick={handleAddVideo}
            >
              <AddIcon style={{ color: '#ffbb34' }} />
            </IconButton>
          )}

          {/* "我的"图标 */}
          <UserProfileIcon />
        </Toolbar>
      </AppBar>
      {/* 添加一条浅灰色的分割线*/}
      <Box sx={{ height: '1px', backgroundColor: '#eeeeee' }} />
    </Box>
  );
}

// 新建的组件 UserProfileIcon
function UserProfileIcon() {
  const [phone, setPhone] = useState('');
  const [totalPoints, setTotalPoints] = useState(0);
  const [userProfile, setUserProfile] = useState({
    nickname: '',
    bio: '',
    avatar: null
  });
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isEditing, setIsEditing] = useState({
    nickname: false,
    bio: false
  });
  const fileInputRef = useRef(null);

  // ����� fetchUserInfo 移到这里，这样其他函数可以访问它
  const fetchUserInfo = async () => {
    try {
      const unique_id = localStorage.getItem('unique_id');
      const [userResponse, pointsResponse, profileResponse] = await Promise.all([
        axios.get(`${GlobalData.serverURL}/get_user_info/?unique_id=${unique_id}`),
        axios.get(`${GlobalData.serverURL}/get_total_points/`),
        axios.get(`${GlobalData.serverURL}/get_user_profile/?unique_id=${unique_id}`)
      ]);

      if (userResponse.status === 200) {
        setPhone(userResponse.data.phone_number);
      }
      if (pointsResponse.status === 200) {
        setTotalPoints(pointsResponse.data.total_points);
      }
      if (profileResponse.status === 200) {
        setUserProfile(profileResponse.data);
      }
    } catch (error) {
      console.error('获取用户信息失败:', error);
    }
  };

  // 添加回这些处理函数
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('unique_id');
    navigate('/login');
  };

  const handleStudyHistory = () => {
    window.open('/study-history', '_blank');
  };

  const handlePointsDetail = () => {
    window.open('/points-detail', '_blank');
  };

  const handleEditProfile = () => {
    window.open('/profile-edit', '_blank');
  };

  // 处理头像更新
  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('选择的文件:', {
        name: file.name,
        type: file.type,
        size: `${(file.size / 1024 / 1024).toFixed(2)}MB`
      });

      if (file.size > 5 * 1024 * 1024) {
        message.error('图片大小不能超过5MB');
        return;
      }

      try {
        const formData = new FormData();
        const unique_id = localStorage.getItem('unique_id');

        // 确保所有必需字段都有值
        if (!unique_id) {
          message.error('用户ID不能为空');
          return;
        }

        // 使用当前值或空字符串，避免undefined
        const currentNickname = userProfile.nickname || '';
        const currentBio = userProfile.bio || '';

        console.log('准备发送请求:', {
          unique_id,
          nickname: currentNickname,
          bio: currentBio,
          'has_avatar': true
        });

        formData.append('avatar', file);
        formData.append('nickname', currentNickname);
        formData.append('bio', currentBio);
        formData.append('unique_id', unique_id);

        // 打印FormData内容进行检查
        for (let pair of formData.entries()) {
          console.log('FormData 内容:', pair[0], typeof pair[1], pair[1]);
        }

        const response = await axios.post(
          `${GlobalData.serverURL}/update_user_profile/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('服务器响应:', response.data);

        if (response.status === 200) {
          message.success('头像更新成功');
          await fetchUserInfo();
        }
      } catch (error) {
        console.error('更新头像失败:', error);
        console.error('错误详情:', {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
          detail: error.response?.data?.detail,
          message: error.response?.data?.message
        });

        // 显示更详细的错误信息
        if (error.response?.data?.detail) {
          const detail = error.response.data.detail;
          if (Array.isArray(detail)) {
            detail.forEach(err => {
              console.error('验证错误:', err);
              message.error(`${err.loc.join('.')}：${err.msg}`);
            });
          } else {
            message.error(detail);
          }
        } else {
          message.error('更新头像失败');
        }
      }
    }
  };

  // 处理昵称和简介的更新
  const handleProfileUpdate = async (field, value) => {
    try {
      const formData = new FormData();
      formData.append('nickname', field === 'nickname' ? value : userProfile.nickname || '');
      formData.append('bio', field === 'bio' ? value : userProfile.bio || '');
      formData.append('unique_id', localStorage.getItem('unique_id'));

      const response = await axios.post(
        `${GlobalData.serverURL}/update_user_profile/`,
        formData
      );

      if (response.status === 200) {
        message.success(`${field === 'nickname' ? '昵称' : '简介'}更新成功`);
        setUserProfile(prev => ({
          ...prev,
          [field]: value
        }));
        setIsEditing({ ...isEditing, [field]: false });
      }
    } catch (error) {
      console.error('更新失败:', error);
      message.error('更新失败');
    }
  };

  useEffect(() => {
    if (popoverOpen) {
      fetchUserInfo();
    }
  }, [popoverOpen]);

  // Popover 的内容
  const content = (
    <Box sx={{ width: 250 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        {/* 头像部分 - 点击可更换 */}
        <Avatar
          src={userProfile.avatar ? `${GlobalData.serverURL}/avatars/${userProfile.avatar}` : null}
          sx={{
            width: 60,
            height: 60,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            }
          }}
          onClick={handleAvatarClick}
        />
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept="image/*"
          onChange={handleAvatarChange}
        />

        <Box sx={{ flex: 1 }}>
          {/* 昵称部分 - 可编辑 */}
          {isEditing.nickname ? (
            <TextField
              size="small"
              defaultValue={userProfile.nickname || phone}
              autoFocus
              onBlur={(e) => handleProfileUpdate('nickname', e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleProfileUpdate('nickname', e.target.value);
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '28px',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#333',
                  '& fieldset': {
                    borderColor: 'transparent',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ffbb34',
                    borderWidth: '1px',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  padding: '4px 8px',
                },
              }}
            />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                color: '#333',
                cursor: 'pointer',
                padding: '4px 8px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }}
              onClick={() => setIsEditing({ ...isEditing, nickname: true })}
            >
              {userProfile.nickname || phone}
            </Typography>
          )}

          {/* 简介部分 - 可编辑 */}
          {isEditing.bio ? (
            <TextField
              size="small"
              multiline
              maxRows={3}
              placeholder="编辑个性签名"
              defaultValue={userProfile.bio || ''}
              autoFocus
              onBlur={(e) => handleProfileUpdate('bio', e.target.value)}
              sx={{
                marginTop: '4px',
                '& .MuiOutlinedInput-root': {
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  '& fieldset': {
                    borderColor: 'transparent',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ffbb34',
                    borderWidth: '1px',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  padding: '4px 8px',
                },
              }}
            />
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                padding: '4px 8px',
                marginTop: '4px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }}
              onClick={() => setIsEditing({ ...isEditing, bio: true })}
            >
              {userProfile.bio || '编辑个性签名'}
            </Typography>
          )}
        </Box>
      </Box>

      <Divider style={{ margin: '8px 0' }} />

      {/* 积分部分 - 移到 Divider 下面，使用与其他选项相同的样式 */}
      <Box
        sx={{
          p: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': { backgroundColor: '#f5f5f5' },
          borderRadius: 1
        }}
        onClick={handlePointsDetail}
      >
        <StarIcon sx={{ color: '#ffbb34' }} />
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#ffbb34' }}>
            {totalPoints}
          </Typography>


        </Box>
      </Box>

      <Box
        sx={{
          p: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': { backgroundColor: '#f5f5f5' },
          borderRadius: 1
        }}
        onClick={handleStudyHistory}
      >
        <HistoryIcon sx={{ color: '#666' }} />
        <Typography variant="body2">学习记录</Typography>
      </Box>

      <Box
        sx={{
          p: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&:hover': { backgroundColor: '#f5f5f5' },
          borderRadius: 1,

        }}
        onClick={handleLogout}
      >
        <LogoutIcon sx={{ color: '#666' }} />
        <Typography variant="body2">退出登录</Typography>
      </Box>
    </Box>
  );

  // 在组件挂载时获取用户信息
  useEffect(() => {
    fetchUserInfo();
  }, []); // 空依赖数组，只在组件挂载时执行一次

  return (
    <Popover
      content={content}
      trigger="hover"
      placement="bottomRight"
      overlayStyle={{ padding: 0 }}
      overlayInnerStyle={{ padding: 16, borderRadius: 8 }}
      onOpenChange={(visible) => setPopoverOpen(visible)}
    >
      {userProfile.avatar ? (
        // 如果有用户头像，直接显示 Avatar
        <Avatar
          src={`${GlobalData.serverURL}/avatars/${userProfile.avatar}`}
          sx={{
            width: 32,
            height: 32,
            cursor: 'pointer',
            marginLeft: '8px', // 添加左边距，保持与其他图标的间距
            '&:hover': {
              opacity: 0.8
            }
          }}
        />
      ) : (
        // 如果没有用户头像，显示默认图标
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          color="inherit"
        >
          <AccountCircleOutlined style={{ color: '#ffbb34' }} />
        </IconButton>
      )}
    </Popover>
  );
}

export default MyAppBar;