import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WordCard from './WordCard';
import { GlobalData } from '../global_data';
import axios from 'axios';
import { throttle } from 'lodash';
import MyAppBar from './MyAppBar';

function WordListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [words, setWords] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [bookInfo, setBookInfo] = useState(null);

  // 添加时间调整配置
  const TIME_PADDING = {
    before: 2, // 开始时间提前的秒数
    after: 2   // 结束时间延后的秒数
  };

  // 获取URL参数
  const searchParams = new URLSearchParams(location.search);
  const bookId = searchParams.get('bookId');
  const isWordbook = searchParams.get('isWordbook') === 'true';
  const title = searchParams.get('title');

  // 获取单词列表
  const fetchWords = async (currentPage) => {
    if (isLoading || !hasMore) return;

    console.log('开始获取单词列表:', {
      isWordbook,
      bookId,
      title,
      currentPage
    });

    setIsLoading(true);
    try {
      let response;
      if (isWordbook) {
        // 获取用户的单词本
        console.log('获取用户单词本数据');
        response = await axios.get(
          `${GlobalData.serverURL}/get_word_book/?page=${currentPage}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );
      } else {
        // 获取词书的单词
        console.log('获取普通词书数据');
        response = await axios.get(
          `${GlobalData.serverURL}/get_book_words/?book_id=${bookId}&page=${currentPage}`
        );
      }

      if (response.status === 200) {
        const newWords = response.data.words;
        console.log('获取到的单词数据:', {
          isWordbook,
          count: newWords.length,
          sample: newWords.slice(0, 2).map(w => ({
            word: w.word,
            hasVideo: !!w.video_filename,
            context: w.context,
            videoInfo: w.video_filename ? {
              filename: w.video_filename,
              startTime: w.subtitle_start_time,
              endTime: w.subtitle_end_time,
              startTimeType: typeof w.subtitle_start_time,
              endTimeType: typeof w.subtitle_end_time,
              rawStartTime: w.subtitle_start_time,
              rawEndTime: w.subtitle_end_time,
              startTimeFormat: w.subtitle_start_time ?
                `${w.subtitle_start_time} (${typeof w.subtitle_start_time})` : 'undefined',
              endTimeFormat: w.subtitle_end_time ?
                `${w.subtitle_end_time} (${typeof w.subtitle_end_time})` : 'undefined',
            } : null
          }))
        });

        // 处理单词数据
        const processedWords = newWords.map(word => {
          // 如果是词书单词（非单词本）且有视频信息，调整时间范围
          if (!isWordbook && word.video_filename) {
            return {
              ...word,
              subtitle_start_time: Math.max(0, Number(word.subtitle_start_time) - TIME_PADDING.before),
              subtitle_end_time: Number(word.subtitle_end_time) + TIME_PADDING.after
            };
          }
          // 如果是单词本的数据，确保context字段存在
          if (isWordbook) {
            return {
              ...word,
              context: word.context || word.explanation // 尝试使用context或explanation字段
            };
          }
          return word;
        });

        setWords(prev => currentPage === 1 ? processedWords : [...prev, ...processedWords]);
        setHasMore(newWords.length > 0);
      }
    } catch (error) {
      console.error('获取单词列表失败:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 处理滚动加载
  const handleScroll = throttle(() => {
    if (isLoading || !hasMore) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setPage(prev => prev + 1);
    }
  }, 500);

  // 监听滚动事件
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [handleScroll]);

  // 监听页码变化，加载更多单词
  useEffect(() => {
    fetchWords(page);
  }, [page]);

  // 返回首页
  const handleBack = () => {
    window.close(); // 关闭当前标签页
  };

  const dividerTextStyle = {
    my: 2,
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      content: '""',
      borderTop: '1px solid',
      borderColor: 'divider',
      width: '80px',
      mx: 2
    }
  };

  return (
    <div>
      <MyAppBar
        title=""
        showSearch={false}
        showAdd={false}
        showTabs={false}
      />
      <Box sx={{ width: '100%', p: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            {title}
          </Typography>
        </Box>

        {words.map((word, index) => {
          console.log('渲染单词卡片:', {
            word: word.word,
            hasVideo: !!word.video_filename,
            isWordbook,
            context: word.context,
            videoInfo: word.video_filename ? {
              filename: word.video_filename,
              startTime: word.subtitle_start_time,
              endTime: word.subtitle_end_time
            } : null
          });

          return (
            <WordCard
              key={`${word.word}-${index}`}
              word={word.word}
              definition={word.definition}
              translation={word.translation}
              phonetic={word.phonetic}
              videoFilename={word.video_filename}
              subtitleStartTime={word.subtitle_start_time}
              subtitleEndTime={word.subtitle_end_time}
              subtitleText={word.subtitle_text}
              context={isWordbook ? word.context : undefined}
              videoTitle={word.video_title}
              isWordbook={isWordbook}
            />
          );
        })}

        {isLoading && (
          <Typography variant="body2" align="center" sx={dividerTextStyle}>
            加载中...
          </Typography>
        )}

        {!isLoading && !hasMore && words.length > 0 && (
          <Typography variant="body2" align="center" sx={dividerTextStyle}>
            到底啦
          </Typography>
        )}
      </Box>
    </div>
  );
}

export default WordListPage; 